.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* font */
@font-face {
	font-family: OswaldSemiBold;
	src: url("./fonts/Oswald-SemiBold.ttf");
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 5px;
}


/** css **/
body{
  /*background: #0E0737 !important;*/
  background: #BB683A !important;
  overscroll-behavior: auto !important;
}

.body{
  width: 100%;
  height: auto;
  overflow-x: hidden;
  overscroll-behavior: auto !important;
  background: url("./assets/bg1-min.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

/* Navigation */
#logo-heading{
  /*width: 100%;*/
  width: 100px;
  position: relative;
  left: 8.5%;
}
#logo-heading img{
  width: 100px;
}
.nav-items{
  color: white !important;
  padding-right: 40px !important;
  font-family: OswaldSemiBold !important;
}
.navbarBg{
  width: 100%;
  background: url("../src/assets/navbar.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.navbar-collapse{
  background: transparent !important;
  padding: 20px !important;
}
.links{
  position: relative;
  top: 5px;
  left: 45%;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  font-family: OswaldSemiBold !important;
}
.show{
  background-color: black !important;
}
.mbl-title{
  color: white !important;
}
.btn-close{
  color: white !important;
}
#close{
  color: white !important;
}

.offcanvas-header .btn-close{
  background: url("../src/assets/close.png");
  background-size: contain;
}

.navbar-toggler-icon{
  background: url("../src/assets/bar.png") !important;
  background-size: 100% 100% !important;
}
.play-btn button{
  /*background-color: #FF0000 ;
  border: #FF0000 2px solid;*/
  background: url("./assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  color: white;
  transition: transform .2s;
  position: relative;
  top: -10px;
  font-family: OswaldSemiBold;
}
.play-btn button:hover{
  transform: scale(1.1);
  transition: transform .2s;
}
#mobile-link{
  display: none;
  /*background-color: #FF0000 ;
  border: #FF0000 2px solid;*/
  /*background: url("./assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  border-radius: 10px;*/
  color: white;
  width: 45%;
}

/* Header */
#header{
  width: 100%;
  height: 120vh;
  margin: 0px auto;
  background: url("../src/assets/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.main-container{
  width: 100%;
}

/* about */
#about{
  width: 100%;
  padding: 80px 50px 50px 50px;
}
.main-aboutcontainer{
  width: 100%;
}
.aboutus{
  width: 100%;
}
.about-text{
  width: 60%;
  text-align: center;
  padding-top: 60px;
  margin-left: auto;
  margin-right: auto;
}
.about-text p{
  color: white;
  font-family: OswaldSemiBold;
  padding-top: 10px;
}
.about-heading{
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.about-heading img{
  width: 50%;
}

/* Content 
#Content{
  width: 100%;
  padding: 80px 50px 50px 50px;
  padding-bottom: 10px !important;
}
.content-mainContainer{
  width: 100%;
}
.content-title{
  width: 100%;
  text-align: center;
}
.content-title h2{
  color: white;
  font-family: HelveticaRoundedLTStd;
  padding-bottom: 20px;
  font-size: 30px;
}
.content-title p{
  color: white;
  font-family: HelveticaRoundedLTStd;
}
.content-container{
  width: 100%;
}
.inline-div{
  width: 32%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.inline-div h4{
  color: white;
  font-family: HelveticaRoundedLTStd;
}
.inline-div p{
  color: white;
  font-family: HelveticaRoundedLTStd;
}*/

/* game trailer */
#game-trailer{
  width: 100%;
  height: auto;
  padding: 100px 50px 50px 50px;
  padding-bottom: 10px !important;
}
.main-game-trailer{
  width: 100%;
}
.game-trailer-title{
  width: 100%;
  text-align: center;
}
.game-trailer-title img{
  width: 20%;
}
/*.game-image{
  width: 100%;
  text-align: center;
  padding-top: 30px;
  position: relative;
}
.game-image img{
  width: 70%;
}*/
#dog-container{
  width: 20%;
  position: absolute;
  top: -120px;
  right: 100px;
}
#dog-container img{
  width: 100px;
}

.game{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.game-btn button{
  /*background-color: #FF0000 ;
  border: #FF0000 2px solid;*/

  background: url("./assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  transition: transform .2s;
  border: none;
  font-family: OswaldSemiBold;
}
.game-btn button:hover{
  transform: scale(1.1);
  transition: transform .2s;
}

/*.game-image video{
  width: 70%;
  border-radius: 10px;
}*/

.game-image {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  position: relative;
}

.frame {
  position: relative;
  display: inline-block;
  width: 70%;
  max-width: 100%;
}

.frame-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;
}

.video-inside-frame {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 8px;
  object-fit: cover;
}


/* roadmap */
#roadmap{
  width: 100%;
  height: auto;
  padding-top: 140px;
  padding-bottom: 10px !important;
}
.roadmap-container{
  width: 100%;
}
.roadmap-title{
  width: 100%;
  text-align: center;
}
.roadmap-title img{
  width: 30%;
}
.roadmap-image{
  width: 100%;
  text-align: center;
  padding-top: 30px;
}
.roadmap-image img{
  width: 100%;
}

/* Tokenomics */
#tokenomics {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 50px;
  display: flex; /* Centers the content vertically */
  justify-content: center; /* Centers the content horizontally */
}

/*#sidelight{
  width: 20%;
  position: absolute;
  left: 0;
}
#sidelight img{
  width: 250px;
  mix-blend-mode: color-dodge;
}*/
#tokenomics-dog{
  width: 20%;
  position: absolute;
  left: 40px; 
}
#tokenomics-dog img{
  width: 250px;
  position: relative;
  top: 250px;
}

.tokenomics-container {
  width: 100%;
  display: flex;
  flex-direction: column; /* Aligns items in a column */
  align-items: center; /* Centers items horizontally */
}

.tokenomics-title {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center; /* Centers the title image horizontally */
}

.tokenomics-title img {
  width: 30%;
}

.chart-container {
  width: 100%;
  display: flex;
  justify-content: center; /* Centers the chart horizontally */
  align-items: center; /* Centers the chart vertically if needed */
  padding-top: 30px;
}



/* Text image section */
#textimg-section {
  width: 100%;
  padding: 0 40px 100px 40px;
  overflow: hidden;
}

.sldier-carousel {
  max-width: 1200px; /* Adjust based on the maximum width you want */
  margin: 0 auto; /* Center the slider */
  position: relative; /* Adjust positioning if needed */
  padding-top: 30px;
}

.text-img-slider {
  padding: 0 15px; /* Adds spacing between slides */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-img-slider img {
  width: 100%; /* Ensures the images fill the slider item */
  height: auto; /* Maintains image aspect ratio */
}

.textimg-intro {
  text-align: center;
  width: 100%;
}

.textimg-intro img{
  width: 10%;
}


/* footer */
#footer{
  width: 100%;
  height: auto;
  margin: 0px auto;
  padding-top: 100px;
  padding-bottom: 150px;
}
#footer-dog{
  width: 20%;
  position: absolute;
  right: 50px;
}
#footer-dog img{
  width: 250px;
  position: relative;
  top: 100px;
}
.contact-container{
  width: 100%;
  text-align: center;
}
.contact-heading{
  width: 100%;
  text-align: center;
}
.contact-heading img{
  width: 30%;
  padding-bottom: 30px;
}
form{
  width: 100% !important;
}
.contactForm {
  width: 100% !important;
}
#input-field{
  background-color: rgba(255, 255, 255, 0.2) !important;
  width: 50% !important;
  color: white !important;
  font-family: OswaldSemiBold;
}
#text-field{
  background-color: rgba(255, 255, 255, 0.2) !important;
  width: 50% !important;
  color: white;
  height: 200px !important;
  font-family: OswaldSemiBold;
}
.submit-btn{
  width: 15% !important;
  background: url("./assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 30px 20px 30px;
  border: none !important;
  border-radius: 18px !important;
}
#contact-btn{
  color: white !important;
}
.contactForm ::placeholder{
  color: white !important;
  font-family: OswaldSemiBold;
}


/* socials */
#socials{
  width: 100%;
  height: auto;
  padding: 40px 50px 30px 50px;
}
.inner-container{
  width: 100%;
}
.social-container {
  display: flex; /* Use Flexbox for alignment */
  justify-content: center; /* Center the items horizontally */
  gap: 20px; /* Space between icons */
  padding-top: 20px; /* Optional: Add padding to separate from other content */
  text-align: center; /* Ensure text is centered if needed */
}

.social-container .social {
  color: white !important;
  text-decoration: none; /* Remove underline from links */
  transition: transform 0.3s; /* Optional: Smooth transition for hover effect */
}

.social-container .social:hover {
  transform: scale(1.1); /* Optional: Slightly enlarge icon on hover */
}
