@media (min-width: 1280px) and (max-width: 1500px){
    .links{
        left: 35%;
    }
}

@media (min-width: 1200px) and (max-width: 1280px) {
    .links {
        left: 30% !important;
    }
}

@media (min-width: 992px) and (max-width: 1280px) {

    /* navbar */
    .links {
        left: 0;
    }

    /* about */
    .aboutimg {
        margin-top: 50px;
    }

    /* footer */
    #footer-dog{
        right: 50px;
      }
      #footer-dog img{
        width: 200px;
        top: 120px;
      }
}

@media (min-width: 992px) and (max-width: 1200px){
    .play-btn button{
        display: none !important;
    }
    #mobile-link{
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    /* navbar */
    .links {
        left: 0;
    }
    .navbarBg{
        margin-top: -25px;
    }
    .play-btn button{
        display: none !important;
    }
    #mobile-link{
        display: block;
    }

    /* header */
    #header {
        width: 100%;
        height: 100vh;
        background-position: center center;
        padding-top: 20px;
        padding-bottom: 100px;
    }
    .title{
        width: 100%;
        text-align: center;
        padding-top: 170px;
      }
      .title h2{
        text-align: center;
      }
      .headercontent{
        text-align: center;
      }
      .btn-container{
        text-align: center;
      }

    .main-container {
        width: 100%;
    }

    .title {
        width: 100%;
    }

    /* about */
    #about {
        width: 100%;
    }

    .main-aboutcontainer {
        width: 100%;
    }

    .aboutus {
        width: 100%;
    }

    .leftabout-container {
        width: 100%;
        float: none;
    }

    .about-text {
        width: 100%;
        text-align: center;
    }

    .rightabout-container {
        width: 100%;
        float: none;
    }

    .aboutimg {
        width: 50%;
        margin-left: 160px;
    }

    /* Content */
    .inline-div{
        width: 100%;
        display: block;
    }

    /* game trailer */
    #game-trailer {
        width: 100%;
    }

    .main-game-trailer {
        width: 100%;
    }

    .game-trailer-title {
        width: 100%;
    }

    .game-image {
        width: 100%;
    }

    .game-image img {
        width: 100%;
    }

    .game-image video {
        width: 80%;
    }

    #dog-container{
        width: 10%;
        top: -120px;
        right: 80px;
      }
      #dog-container img{
        width: 100px;
      }

    /* roadmap */
    #roadmap {
        width: 100%;
    }

    .roadmap-container {
        width: 100%;
    }

    .roadmap-title {
        width: 100%;
    }

    /* footer */
    #footer {
        width: 100%;
        background-position: 15% center;
    }
    #footer-dog{
        right: 50px;
    }
    #footer-dog img{
        width: 170px;
        top: 120px;
    }
}

@media only screen and (max-width: 767px) {

    /* popup */
    .popup{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
        margin-top: 50px;
      } 

    /* navbar */
    .links {
        left: 0;
    }
    .navbarBg{
        margin-top: -25px;
    }
    .play-btn button{
        display: none !important;
    }
    #mobile-link{
        display: block;
    }

    /* header */
    #header {
        width: 100%;
        height: 100vh;
        background-position: center center;
        padding-top: 20px;
        padding-bottom: 100px;
    }

    .main-container {
        width: 100%;
    }

    .title {
        width: 100%;
    }

    .title h2 {
        text-align: center;
    }

    .headercontent p {
        text-align: center;
        padding-top: 30px;
        line-height: 1.8em;
    }

    .btn-container {
        text-align: center;
        padding-top: 10px;
        margin-left: 0;
    }

    .headercontent br {
        display: none;
    }

    /* about */
    #about {
        width: 100%;
        padding: 5px;
    }

    .main-aboutcontainer {
        width: 100%;
    }

    .aboutus {
        width: 100%;
    }

    .leftabout-container {
        width: 100%;
        float: none;
    }

    .about-text {
        width: 100%;
        text-align: center;
    }

    .rightabout-container {
        width: 100%;
        float: none;
    }

    .aboutimg {
        width: 100%;
        margin-left: 0;
    }

    .about-text br {
        display: none !important;
    }

        /* Content */
        .inline-div{
            width: 100%;
            display: block;
        }

    /* game trailer */
    #game-trailer {
        width: 100%;
    }

    .main-game-trailer {
        width: 100%;
    }

    .game-trailer-title {
        width: 100%;
    }

    /*.game-image {
        width: 100%;
    }*/

    .game-image img {
        width: 100%;
    }
    #dog-container{
        width: 10%;
        top: -90px;
        right: 50px;
      }
      #dog-container img{
        width: 80px;
      }

    /*.game-image video {
        width: 100%;
    }*/

    /* roadmap */
    #roadmap {
        width: 100%;
    }

    .roadmap-container {
        width: 100%;
    }

    .roadmap-title {
        width: 100%;
    }

    .roadmap-content {
        background: none !important;
    }

    .content {
        padding-bottom: 30px;
        text-align: left;
        padding-left: 20px;
    }

    /* tokenomics */
    #tokenomics{
        width: 100%;
    }
    .tokenomics-container{
        width: 100%;
      }
    #tokenomics-dog{
        display: none;
    }
    .piechart{
        height: 400px !important;
    }

    /* footer */
    #footer {
        width: 100%;
        background-position: 15% center;
        padding-left: 15px;
        padding-right: 15px;
    }
    #footer-dog{
        display: none;
    }

      .left-tokenContainer{
        width: 100%;
        float: none;
      }

      .left-tokenContainer h3{
        padding-left: 20px;
        padding-right: 10px;
      }
      .left-tokenContainer p{
        padding-left: 20px;
        padding-right: 20px;
      }

      .right-tokenContainer{
        width: 100%;
        float: none;
      }

      .right-tokenContainer h3{
        padding-left: 20px;
        padding-right: 10px;
      }
      .right-tokenContainer p{
        padding-left: 20px;
        padding-right: 20px;
      }


      form{
        width: 100% !important;
      }
      .contactForm {
        width: 100% !important;
      }
      #input-field{
        width: 100% !important;
      }
      #text-field{
        width: 100% !important;
      }
      .submit-btn{
        width: 50% !important;
      }
}